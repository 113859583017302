import axios, { AxiosInstance, AxiosPromise } from 'axios';
import router from '@/router';
import storeApp from '@/store/storeApp';

const authHeader = () => ({
  TokenComunicacao: '6208576e24ee868558a3a12bb559317c',
});

export default class ApiERP {
  protected instance: AxiosInstance;

  protected readonly baseURL: string;

  public constructor() {
    this.baseURL = storeApp.state.configuracaoApp.urlApiCommerce;
    this.instance = axios.create({
      baseURL: this.baseURL,
      headers: {
        TokenComunicacao: '6208576e24ee868558a3a12bb559317c',
        'Content-Type': 'application/json',
      },
    });

    this.interceptaAxios();
  }

  public get(path?: string): AxiosPromise<unknown> {
    return this.instance({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  public post(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }

  public patch(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  public upload(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), 'Content-Type': 'multipart/form-data' },
    });
  }

  public delete(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  public put(path?: string, data?: unknown): AxiosPromise<unknown> {
    return this.instance({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  public interceptaAxios():void {
    /**
     * axios interceptors runs before and after a request, letting the developer modify req,req more
     * For more details on axios interceptor see https://github.com/axios/axios#interceptors
     */
    this.instance.interceptors.request.use((config) => {
      // do something before executing the request
      // For example tag along the bearer access token to request header or set a cookie
      const requestConfig = config;
      requestConfig.headers.TokenComunicacao = '6208576e24ee868558a3a12bb559317c';

      return requestConfig;
    });

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
        const { response } = error;
        const originalRequest = error.config;

        if (response) {
          if (response.status === 404) {
            router.push({ name: 'SolicitacaoNaoEncontrada' });
          } else if (response.status === 500) {
            router.push({ name: 'DesculpeNos' });
          } else {
            return originalRequest;
          }
        } else {
          router.push({ name: 'DesculpeNos' });
        }

        return Promise.reject(error);
      },
    );
  }
}
