
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IParceiro } from '@/models/Entidades/IParceiro';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoApp from '@/servicos/ServicoApp';
import ServicoUtilitario from '@/servicos/ServicoUtilitario';
import { IResposta } from '@/core/models/IResposta';
import CampoCpfCnpj from '@/core/components/UI/CampoCpfCnpj.vue';

export default defineComponent({
  name: 'MeuNegocio',
  components: {
    ComunicacaoApi,
    CampoCpfCnpj,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta,
    } = useAppBase();
    const servicoApp = new ServicoApp();
    const servicoUtilitario = new ServicoUtilitario();
    const state = reactive({
      parceiro: {} as IParceiro,
      cep: '',
      cpfCnpj: '',
    });

    function limparDados() {
      state.parceiro = {} as IParceiro;
      state.parceiro.codigo = 0;
      state.cpfCnpj = '';
      state.cep = '';
    }

    onBeforeMount(async () => {
      limparDados();
      appBase.carregando = true;
      state.parceiro = await servicoApp.obterDadosMeuNegocio();
      state.cpfCnpj = state.parceiro.cpfCnpj;
      appBase.carregando = false;
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as suas informações.');
      appBase.resposta = await servicoApp.atualizarDadosMeuNegocio(state.parceiro);
      apresentarResposta();
    }

    return {
      appBase,
      state,
      salvar,

    };
  },
});
