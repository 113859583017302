
import { computed, defineComponent } from 'vue';
import Icone from '@/core/components/UI/Icone.vue';

export default defineComponent({
  name: 'CampoEmail',
  components: {
    Icone,
  },
  props: {
    valor: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Informe o seu e-mail',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:valor', 'change', 'blur'],
  setup(props, { emit }) {
    const computedValor = computed({
      get: () => props.valor,
      set: (valor: string) => {
        if (valor === undefined || valor === null) {
          emit('update:valor', '');
        } else {
          emit('update:valor', valor);
        }
      },
    });

    function blur() {
      emit('blur');
    }

    function change() {
      emit('change');
    }
    return {
      props,
      computedValor,
      change,
      blur,
    };
  },
});
