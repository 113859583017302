import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_2 = { class: "ant-input-group-addon" }
const _hoisted_3 = ["disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_icone, {
        class: "ss-icone",
        nome: "email"
      })
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "ant-input",
      disabled: _ctx.props.disabled,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.change())),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.blur())),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedValor) = $event)),
      placeholder: _ctx.props.placeholder
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.computedValor]
    ])
  ]))
}