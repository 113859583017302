import ApiCommerce from '@/core/apis/ApiCommerce';
import { IArquivo } from '@/models/Entidades/IArquivo';

class ServicoArquivo {
  private endPoint = 'arquivos';

  private apiCommerce = new ApiCommerce();

  public async enviarVarios(arquivos: any[]): Promise<IArquivo[]> {
    const data = new FormData();
    for (let i = 0; i < arquivos.length; (i += 1)) {
      data.append('arquivos', arquivos[i]);
    }

    const result: any = await this.apiCommerce.upload(`${this.endPoint}/enviar/varios`, data);
    return result.data;
  }

  public async enviar(arquivo: any): Promise<IArquivo> {
    const data = new FormData();
    data.append('arquivo', arquivo);

    const result: any = await this.apiCommerce.upload(`${this.endPoint}/enviar`, data);
    return result.data;
  }
}
export default ServicoArquivo;
