import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3936b7f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", {
      title: _ctx.props.moverCardTitulo?'Arraste para ordernar':_ctx.props.titulo,
      class: _normalizeClass(_ctx.props.moverCardTitulo? _ctx.props.classTitulo + ' ui-mover':_ctx.props.classTitulo)
    }, _toDisplayString(_ctx.props.titulo), 11, _hoisted_1),
    _renderSlot(_ctx.$slots, "AcoesExtra", {}, undefined, true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.props.classBorda),
      style: _normalizeStyle(_ctx.props.altura?'min-height: ' + _ctx.props.altura + 'px; max-height: ' + _ctx.props.altura + 'px;':'')
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 6)
  ]))
}