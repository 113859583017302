import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Editor, {
      modelValue: _ctx.state.computedValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.computedValue) = $event)),
      "api-key": "no-api-key",
      init: {plugins:[
        'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'prewiew', 'anchor', 'pagebreak',
        'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
        'table', 'emoticons', 'codesample'
    ],toolbar: 'undo redo | styles | bold italic underline | alignleft aligncenter alignright alignjustify |' +
    'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
    'forecolor backcolor emoticons',
    menu: {
        favs: {title: 'menu', items: 'code visualaid | searchreplace | emoticons'}
    },
    automatic_uploads: true,
    images_upload_handler: _ctx.exampleImageUploadHandler,
    language: 'pt_BR'},
      disabled: _ctx.props.disabled,
      onBlur: _ctx.blur
    }, null, 8, ["modelValue", "init", "disabled", "onBlur"])
  ]))
}