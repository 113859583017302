
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';

import { useAppBase } from '@/core/composables/AppBase';
import { IConfiguracao, IConfiguracaoNotificacaoEmail, IConfiguracaoSmtp } from '@/models/Entidades/IConfiguracao';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import Card from '@/core/components/UI/Card.vue';
import ServicoApp from '@/servicos/ServicoApp';
import { IResposta } from '@/core/models/IResposta';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import CampoSenha from '@/core/components/UI/CampoSenha.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import { ETipoNotificacaoEmail } from '@/models/Enumeradores/ETipoNotificacaoEmail';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export interface IVariavelEmail {
variavel: string;
descricao: string;
tiposNotificacoes: ETipoNotificacaoEmail[];
}

export default defineComponent({
  name: 'ConfiguracoesEmails',
  components: {
    ComunicacaoApi,
    Card,
    CampoEmail,
    CampoSenha,
    EditorHtml,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta,
    } = useAppBase();
    const servicoApp = new ServicoApp();

    const state = reactive({
      configuracao: {} as IConfiguracao,
      notificacaoEmailEmEdicao: {} as IConfiguracaoNotificacaoEmail,
      apresentarModalNotificacao: false,
      variaveis: [] as IVariavelEmail[],
      variaveisTipoNotificacao: [] as IVariavelEmail[],
    });

    function limparDados() {
      state.apresentarModalNotificacao = false;
      state.configuracao = {} as IConfiguracao;
      state.configuracao.codigo = 0;
      state.configuracao.smtp = {} as IConfiguracaoSmtp;
      state.configuracao.notificacoesEmails = [];
      state.notificacaoEmailEmEdicao = {} as IConfiguracaoNotificacaoEmail;
      state.variaveisTipoNotificacao = [];
    }

    state.variaveis = [
      { variavel: '{{ Dominio }}', descricao: 'Domínio da Plataforma', tiposNotificacoes: [ETipoNotificacaoEmail.Padrao, ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ NomePlataforma }}', descricao: 'Nome da Plataforma', tiposNotificacoes: [ETipoNotificacaoEmail.Padrao, ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ Titulo }}', descricao: 'Título', tiposNotificacoes: [ETipoNotificacaoEmail.Padrao] },
      { variavel: '{{ Mensagem }}', descricao: 'Mensagem', tiposNotificacoes: [ETipoNotificacaoEmail.Padrao, ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ClienteCodigo }}', descricao: 'Código do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ClienteEmail }}', descricao: 'E-mail do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ClienteTelefone }}', descricao: 'Telefone do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ClienteCpfCnpj }}', descricao: 'CPF/CNPJ do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ClienteIdentificacao }}', descricao: 'Identificação do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ClienteNomeRazaoSocial }}', descricao: 'Nome/Razão Social do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ClienteNomeFantasia }}', descricao: 'Nome Fantasia do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ClienteChaveSenha }}', descricao: 'Chave de Recuperação senha do Cliente', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ReservaNumeroIdentificacao }}', descricao: 'Número do Reserva', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ReservaStatus }}', descricao: 'Status do Reserva', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ReservaValorTotal }}', descricao: 'Valor Total do Reserva', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
      { variavel: '{{ ReservaObservacoes }}', descricao: 'Observações do Reserva', tiposNotificacoes: [ETipoNotificacaoEmail.NovaReserva, ETipoNotificacaoEmail.ReservaConfirmada, ETipoNotificacaoEmail.ReservaCancelada] },
    ];

    function obterDescricaoTipoNotificacao(tipoNotificacao:ETipoNotificacaoEmail) {
      return servicoApp.obterDescricaoTipoNotificacaoEmail(tipoNotificacao);
    }

    function obterDestintariosTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail, destinatarios: string) {
      return servicoApp.obterDestintariosTipoNotificacaoEmail(tipoNotificacao, destinatarios);
    }

    function preencherDestintariosTipoNotificacaoEmail(tipoNotificacao:ETipoNotificacaoEmail) {
      return servicoApp.preencherDestintariosTipoNotificacaoEmail(tipoNotificacao);
    }

    function apresentarModalEdicaoNotificacao(notificacaoEmail:IConfiguracaoNotificacaoEmail) {
      state.notificacaoEmailEmEdicao = UtilitarioGeral.instanciaObjetoLocal(notificacaoEmail);
      state.variaveisTipoNotificacao = [];
      const variaveisNotificacao = UtilitarioGeral.instanciaObjetoLocal(state.variaveis) as IVariavelEmail[];
      variaveisNotificacao.forEach((variavel) => {
        if (variavel.tiposNotificacoes.some((c) => c === notificacaoEmail.tipo)) {
          state.variaveisTipoNotificacao.push(variavel);
        }
      });
      state.apresentarModalNotificacao = true;
    }

    function confirmarAlteracoesNotificaoEmail() {
      const index = state.configuracao.notificacoesEmails.findIndex((c) => c.codigo === state.notificacaoEmailEmEdicao.codigo);
      if (index >= 0) {
        state.configuracao.notificacoesEmails[index] = UtilitarioGeral.instanciaObjetoLocal(state.notificacaoEmailEmEdicao);
        state.notificacaoEmailEmEdicao = {} as IConfiguracaoNotificacaoEmail;
      }
      state.apresentarModalNotificacao = false;
    }

    function copiarVariavel(variavel:string) {
      navigator.clipboard.writeText(variavel);
    }

    onBeforeMount(async () => {
      limparDados();
      appBase.carregando = true;
      state.configuracao = await servicoApp.obterConfiguracoes();
      appBase.carregando = false;
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as suas Configurações.');
      appBase.resposta = await servicoApp.atualizarConfiguracoesEmails(state.configuracao);
      apresentarResposta();
    }

    return {
      appBase,
      state,
      salvar,
      obterDescricaoTipoNotificacao,
      apresentarModalEdicaoNotificacao,
      confirmarAlteracoesNotificaoEmail,
      copiarVariavel,
      obterDestintariosTipoNotificacaoEmail,
      preencherDestintariosTipoNotificacaoEmail,
    };
  },
});
