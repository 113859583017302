
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import Editor from '@tinymce/tinymce-vue';
import ServicoArquivo from '@/servicos/ServicoArquivo';

const __default__ = defineComponent({
  name: 'EditorHtml',
  props: {
    value: {
      type: String,
      default: '',
    },
    minHeight: {
      type: String,
      default: '100',
    },
    maxHeight: {
      type: String,
      default: '500',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Editor,
  },
  emits: ['update:value', 'change', 'blur'],
  setup(props, { emit }) {
    const servicoArquivo = new ServicoArquivo();
    const alturaMinima = ref(`${props.minHeight}px`);
    const alturaMaxima = ref(`${props.maxHeight}px`);
    const state = reactive({
      computedValue: computed({
        get: () => props.value,
        set: (val: string) => {
          emit('update:value', val);

          if (val !== props.value) {
            emit('change', val);
          }
        },
      }),
    });

    async function exampleImageUploadHandler(blobInfo:any) {
      const arquivo = await servicoArquivo.enviar(new File([blobInfo.blob()], blobInfo.filename()));
      return arquivo.link;
    }

    function blur() {
      emit('blur');
    }

    return {
      props,
      alturaMinima,
      alturaMaxima,
      state,
      blur,
      exampleImageUploadHandler,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "29d24eba": (_ctx.alturaMinima),
  "29661e68": (_ctx.alturaMaxima)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__