
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IConfiguracao, IConfiguracaoSmtp } from '@/models/Entidades/IConfiguracao';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoApp from '@/servicos/ServicoApp';
import { IResposta } from '@/core/models/IResposta';

export default defineComponent({
  name: 'Configuracoes',
  components: {
    ComunicacaoApi,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarResposta,
    } = useAppBase();
    const servicoApp = new ServicoApp();

    const state = reactive({
      configuracao: {} as IConfiguracao,
    });

    function limparDados() {
      state.configuracao = {} as IConfiguracao;
      state.configuracao.codigo = 0;
      state.configuracao.smtp = {} as IConfiguracaoSmtp;
    }

    onBeforeMount(async () => {
      limparDados();
      appBase.carregando = true;
      state.configuracao = await servicoApp.obterConfiguracoes();
      appBase.carregando = false;
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as suas Configurações.');
      appBase.resposta = await servicoApp.atualizarConfiguracoes(state.configuracao);
      apresentarResposta();
    }

    return {
      appBase,
      state,
      salvar,
    };
  },
});
